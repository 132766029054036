.c-wrapper {
    position: relative;
    margin-top: $nav-height;
    width: 100%;
    min-height: $app-min-height;
    max-height: 100%;

    @media all and (min-height: $app-max-height) {
        margin-top: 0;
        padding: $nav-height 0;
    }

    &__content {
        position: relative;
        max-height: calc(100vh - #{$nav-height * 2});
        overflow-y: auto;
        z-index: $layer-base;
        color: $color-black;

        @include mq($until: desktop) {
            overflow-y: scroll;
            -webkit-overflow-scrolling: touch;
        }
    }

    @media screen and (min-height: $nav-height-breakpoint) {
        margin-top: 0;
        padding: $nav-height-large 0;
        clip-path: inset(0 0 0 0);

        &__content {
            max-height: calc(100vh - #{$nav-height-large * 2});
        }
    }
}

.screen {
    @extend .o-grid__cell;
    box-sizing: border-box;
    flex-wrap: nowrap !important;

    &--centered {
        @extend .o-grid__cell--align-center;
        @extend .o-grid;
        @extend .o-grid--column;
        @extend .o-grid--auto;
        @extend .o-grid--flush;
        @extend .o-grid--align-center;
        text-align: center;
    }
}

html, body {
    position: fixed;
    width: 100%;
    height: 100%;
    min-height: 100%;
    // overflow-y: hidden;
    // -webkit-overflow-scrolling: auto;

    @media all and (max-height: $app-min-height - 1px) {
        // overflow-y: scroll;
    }
}

body {
    background: $color-background url(../../assets/images/bg.jpg) repeat;
    font-family: $font-face, sans-serif;
    font-weight: $font-weight-regular;
}

///* ========================================================================
//   #MEDIA QUERIES CONFIG
//   ======================================================================== */

$mq-base-font-size: $inuit-global-font-size;

//
// mobile-narrow    Most common width on phones currently
// mobile           iPhone 6/7/8/X and similar
// mobile-wide      iPhone 6/7/8 Plus, Pixel 2 and similar
// tablet           Most common tablet portrait width
// desktop          Anything larger than this is considered desktop
// desktop-wide     Wide desktop screens
//
$width-mobile-narrow: 320px;
$width-mobile: 375px;
$width-mobile-wide: 411px;
$width-tablet: 740px;
$width-desktop: 980px;
$width-desktop-wide: 1300px;

$mq-breakpoints: (
    mobile-narrow: $width-mobile-narrow,
    mobile: $width-mobile,
    mobile-wide: $width-mobile-wide,
    tablet: $width-tablet,
    desktop: $width-desktop,
    desktop-wide: $width-desktop-wide,
);


@if (config(debug) == true) {
    $mq-show-breakpoints: (mobile-narrow, mobile, mobile-wide, tablet, desktop, desktop-wide) !global;
}

///* ========================================================================
//   #CONFIG
//   ======================================================================== */

// Baseline grid lines height.
// Every spacing metric should be based on this.

$inuit-global-baseline: 6px;

// How many grid lines should our spacing unit variants span?
// Each value should be an unitless integer.

$inuit-global-spacing-unit-factor-tiny: 1; // 6px
$inuit-global-spacing-unit-factor-small: 2; // 12px
$inuit-global-spacing-unit-factor: 4; // 24px
$inuit-global-spacing-unit-factor-large: 8; // 48px
$inuit-global-spacing-unit-factor-huge: 16; // 96px

// Spacing values.

$inuit-global-spacing-unit: $inuit-global-baseline * $inuit-global-spacing-unit-factor;
$inuit-global-spacing-unit-tiny: $inuit-global-baseline * $inuit-global-spacing-unit-factor-tiny;
$inuit-global-spacing-unit-small: $inuit-global-baseline * $inuit-global-spacing-unit-factor-small;
$inuit-global-spacing-unit-large: $inuit-global-baseline * $inuit-global-spacing-unit-factor-large;
$inuit-global-spacing-unit-huge: $inuit-global-baseline * $inuit-global-spacing-unit-factor-huge;

// Base typographical styles.

$inuit-global-font-size: 16px;
$inuit-global-line-height: $inuit-global-spacing-unit;

$inuit-font-size-h1: 36px;
$inuit-font-size-h2: 28px;
$inuit-font-size-h3: 24px;
$inuit-font-size-h4: 20px;
$inuit-font-size-h5: 18px;
$inuit-font-size-h6: 16px;

// Page-level wrapper element size.

$inuit-wrapper-width: 1200px;

// A list of aspect ratios that get generated as modifier classes.
/* prettier-ignore */
$inuit-ratios: (
    'square': (1:1),
    '2\\:1': (2:1),
    '4\\:3': (4:3),
    '16\\:9': (16:9),
);

// A list of cropping ratios that get generated as modifier classes.
/* prettier-ignore */
$inuit-crops: (
    'square': (1:1),
    '2\\:1': (2:1),
    '4\\:3': (4:3),
    '16\\:9': (16:9),
);

// Flexgrid alignment suffixes.

$inuit-flexgrid-alignment-values: (
    '--align-start': 'flex-start',
    '--align-center': 'center',
    '--align-end': 'flex-end',
    '--align-baseline': 'baseline',
    '--align-stretch': 'stretch',
);

$inuit-flexgrid-justify-values: (
    '--justify-start': 'flex-start',
    '--justify-center': 'center',
    '--justify-end': 'flex-end',
    '--justify-between': 'space-between',
    '--justify-around': 'space-around',
);

// A map of global config settings. Define any project-level configuration,
// feature switches, etc. in here.

$global-config: (
    env: prod,
    debug: false,
);

// You can access data in this map using the following function:
//
// config(<key>)
//
// Example usage:
//
// @if (config(debug) == true) { ...  }

@function config($key) {
    @return map-get($global-config, $key);
}

///* ========================================================================
//   #GLOBAL
//   ======================================================================== */

// The global settings file contains any project-wide variables; things that
// need to be made available to the entire codebase.

// Default font properties

$font-face: $font-face-pfdindisplay-pro;
$font-weight-light: $font-weight-light-pfdindisplay-pro;
$font-weight-regular: $font-weight-regular-pfdindisplay-pro;
$font-weight-medium: $font-weight-medium-pfdindisplay-pro;
$font-weight-bold: $font-weight-bold-pfdindisplay-pro;

// App size constraints

$app-min-width: $width-mobile-narrow;
$app-max-width: $width-mobile-wide + $inuit-global-spacing-unit;
$app-min-height: 480px;
$app-max-height: 960px;

// Navigation height.

$nav-height: 64px;
$nav-height-large: 72px;
$nav-height-breakpoint: 640px;

// z-index levels.

$layer-base: 0;
$layer-navigation: 100;
$layer-modal: 1000;

// Standardize some UI treatments.

$global-radius: 1.5em;
$global-transition: all 100ms ease-in-out;
$global-drop-shadow: 0 0.5em 0.5em -0.5em $color-shadow-light;
$global-full-shadow: 0em 0em 1.5em 0.2em $color-shadow-light;

// Font size

$header-font-size: 20px;

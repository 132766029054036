///* ========================================================================
//   #COLORS
//   ======================================================================== */

// basic colors
$color-white: #fff;
$color-black: #000;
$color-gray: #f2f2f2;
$color-medium-gray: #9095a1;
$color-transparent: rgba($color-black, 0);

// backgrounds
$color-background: $color-white;

// foregrounds
$color-primary: #277439;
$color-secondary: #fadf46;
$color-tertiary: #7ec364;

// text colors
$color-text-dark: #212b45;
$color-text-light: $color-white;
$color-text-medium: $color-medium-gray;

// shadows
$color-shadow: rgba($color-black, 0.75);
$color-shadow-light: rgba($color-black, 0.5);

.c-gifts-screen {
    min-height: 100%;
    max-height: 350px;
    @include font-size(18px);
    justify-content: flex-start;
    align-items: center;
    flex-direction: row;


    &.screen {
        padding: $unit;
        padding-bottom: $unit-large;
        flex-wrap: wrap !important;

        @include mq($until: mobile) {
            padding-left: $unit-small;
            padding-right: $unit-small;
        }
    }

    p {
        margin-bottom: 0;
        padding-bottom: $unit-small;
    }

    &__code {
        border: 0;
        font-family: Consolas, 'Andale Mono WT', 'Andale Mono', 'Lucida Console',
            'Lucida Sans Typewriter', 'DejaVu Sans Mono', 'Bitstream Vera Sans Mono',
            'Liberation Mono', 'Nimbus Mono L', Monaco, 'Courier New', Courier, monospace;
        @include font-size(36px);
        font-weight: bolder;
        text-align: center;
        text-transform: uppercase;
        color: $color-primary;
        letter-spacing: 2px;
        width: 6em;
        margin-top: auto;
        margin-bottom: $unit-small;
        caret-color: transparent;
        border-bottom: 1px solid rgba($color-primary, 0.15);

        &::placeholder {
            color: rgba($color-primary, 0.4);
        }

        &:focus {
            outline: none;
            border-bottom-color: rgba($color-primary, 0.6);

            &::placeholder {
                color: rgba($color-primary, 0.6);
            }
        }
    }
}

.c-profile-screen {
    @include font-size(18px);
    min-height: 100%;
    padding: 0 0 $unit 0;
    color: $color-text-dark;
    background: linear-gradient(
        to bottom,
        $color-background,
        $color-background 50%,
        $color-gray 50%,
        $color-gray
    );

    &__info {
        flex-wrap: nowrap;

        &-avatar {
            padding: $unit-small $unit-large;

            @include mq($until: mobile) {
                padding: $unit;
            }

            @media all and (max-height: 650px) {
                padding: $unit-tiny $unit;
            }
        }

        h1 {
            @extend %nowrap;
            font-weight: $font-weight-bold;
            @include font-size(24px);
            margin-bottom: $unit-tiny;
        }

        h2 {
            text-transform: uppercase;
            font-weight: $font-weight-light;
            color: $color-text-medium;
            @include font-size(16px);
            letter-spacing: 1px;
        }
    }

    &__stats {
        background: $color-gray;

        h2 {
            @include font-size(14px);
            font-weight: $font-weight-medium;
            text-transform: uppercase;
            letter-spacing: 0.05em;
            margin-bottom: 0;
        }

        &-item {
            min-height: max-content;

            .c-btn, img {
                width: 100%;
            }

            h3 {
                @include font-size(30px);
                line-height: 1em;
                font-weight: $font-weight-bold;
                margin-bottom: $unit-small;

                @include mq($until: mobile) {
                    @include font-size(26px);
                    line-height: 1em;
                }
            }

            &__desc {
                display: block;
                text-align: center;
                @include font-size(16px);
                line-height: 1.2em;
                padding: 0 $unit-small;
                color: $color-text-medium;

                @include mq($until: mobile) {
                    @include font-size(14px);
                    line-height: 1.2em;
                }
            }
        }
    }

    &.screen {
        align-self: auto;

        .c-profile-screen {
            &__info,
            &__stats {
                padding: $unit-small;
                min-height: 50%;

                @media all and (max-height: 500px) {
                    min-height: min-content;
                }
            }
            &__stats {
                padding: $unit-small $unit 0;

                @media all and (max-height: 650px) {
                    padding: $unit-tiny $unit 0;
                }
            }
        }
    }
}

.c-footer {
    position: fixed;
    left: 0;
    bottom: 0;
    width: 100%;
    @extend %navigation-size;

    @media all and (min-height: $app-max-height) {
        position: absolute;
    }

    &__content {
        @extend %app-component;
        background: white;
        box-shadow: 0 88px 60px 60px $color-shadow-light;
        padding: 0 50px 0 50px;
    }
}

%app-component {
    width: 100%;
    height: 100%;
    max-width: $app-max-width;
    min-width: $app-min-width;
    max-height: 72px;
    margin: 0 auto;
}

.c-app {
    @extend %app-component;
    min-height: $app-min-height;
    max-height: $app-max-height;
    outline: 1px solid $color-primary;
    background: $color-background;
    box-shadow: 0px 0px 50px -25px $color-shadow;
    overflow-y: auto;
}

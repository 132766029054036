.c-gift-details {
    padding: $unit-small $unit;
    background: white;
    text-transform: uppercase;
    letter-spacing: 1px;
    border-radius: $global-radius;
    @include font-size(16px);
    line-height: 1.2em;
    box-shadow: $global-full-shadow;
    -webkit-tap-highlight-color: $color-transparent;
    -moz-tap-highlight-color: $color-transparent;
    margin-bottom: 16px;
    flex-direction: column;
}

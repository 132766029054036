$font-face-pfdindisplay-pro: PFDinDisplayPro;
$font-weight-light-pfdindisplay-pro: 300;
$font-weight-regular-pfdindisplay-pro: normal;
$font-weight-medium-pfdindisplay-pro: 500;
$font-weight-bold-pfdindisplay-pro: bold;

@font-face {
    font-family: $font-face-pfdindisplay-pro;
    src: url('../../assets/fonts/pfdindisplaypro-light.woff2') format('woff2'),
        url('../../assets/fonts/pfdindisplaypro-light.woff2') format('woff');
    font-weight: $font-weight-light-pfdindisplay-pro;
    font-style: normal;
}

@font-face {
    font-family: $font-face-pfdindisplay-pro;
    src: url('../../assets/fonts/pfdindisplaypro-regular.woff2') format('woff2'),
        url('../../assets/fonts/pfdindisplaypro-regular.woff2') format('woff');
    font-weight: $font-weight-regular-pfdindisplay-pro;
    font-style: normal;
}

@font-face {
    font-family: $font-face-pfdindisplay-pro;
    src: url('../../assets/fonts/pfdindisplaypro-medium.woff2') format('woff2'),
        url('../../assets/fonts/pfdindisplaypro-medium.woff2') format('woff');
    font-weight: $font-weight-medium-pfdindisplay-pro;
    font-style: normal;
}

@font-face {
    font-family: $font-face-pfdindisplay-pro;
    src: url('../../assets/fonts/pfdindisplaypro-bold.woff2') format('woff2'),
        url('../../assets/fonts/pfdindisplaypro-bold.woff2') format('woff');
    font-weight: $font-weight-bold-pfdindisplay-pro;
    font-style: normal;
}

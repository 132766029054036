.u-container {
    position: relative;
    box-sizing: border-box;
    @extend .o-grid;
    @extend .o-grid--justify-center;
    @extend .o-grid--flush;
    @extend .o-grid--align-center;
    @extend .o-grid--auto;
    @extend .o-grid__cell;
}

.u-container-column {
    @extend .u-container;
    flex-direction: column;
}

.u-pull-vertical-40-percent {
    transform: translateY(-40%);
}

.u-pull-vertical-20-percent {
    transform: translateY(-20%);
}

.u-overflow-text-container {
    @extend %nowrap;
    min-width: 0;
}

.c-text-input {
    padding: $unit-small $unit;
    background: white;
    letter-spacing: 1px;
    border-radius: $global-radius;
    @include font-size(16px);
    line-height: 0.8em;
    width: 100%;
    max-width: 70%;
    box-shadow: $global-drop-shadow;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    -webkit-tap-highlight-color: $color-transparent;
    -moz-tap-highlight-color: $color-transparent;
}

@mixin progress-bar-width($width) {
    &__progress {
        left: calc(#{-$width});
        top: calc(#{-$width});
        width: calc(100% + #{2 * $width});
        height: calc(100% + #{2 * $width});

        &__center {
            position: absolute;
            left: ($width - 1px);
            top: ($width - 1px);
            width: calc(100% - #{2 * ($width - 1px)});
            height: calc(100% - #{2 * ($width - 1px)});
            background: $color-background;
            border-radius: 50%;
        }
    }
}

.c-avatar {
    position: relative;
    width: 35%;
    min-width: 60px;
    border-radius: 50%;
    border: 2px solid $color-text-light;

    &__content,
    &__progress {
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        border-radius: 50%;
    }

    &__content {
        z-index: 1;
        background-size: cover;
    }

    @include progress-bar-width(6px);
}

.CircularProgressbar .CircularProgressbar-path {
    stroke: $color-tertiary;
    stroke-linecap: butt;
    transition: stroke-dashoffset 0.5s ease 0s;
}

.CircularProgressbar .CircularProgressbar-trail {
    stroke: transparent;
}

.c-avatar-points {
    position: relative;
    width: 70%;
    min-width: 60px;
    border-radius: 50%;
    border: 2px solid $color-text-light;
    background-color: "#F2F2F2";

    &__content,
    &__progress {
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        border-radius: 50%;
    }

    &__content {
        z-index: 1;
        background-position: center center;
        background-size: 80% 80%;
        background-repeat: no-repeat;
        left: 5px;
        top: 5px;
        width: calc(100% - 10px);
        height: calc(100% - 10px);
    }

    @include progress-bar-width(46px);
}

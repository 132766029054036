/* ==========================================================================
   #BUTTONS
   ========================================================================== */

/**
 * This is an example component. Extend inuitcss by building your own components
 * that make up your UI. Component classes are prefixed with a `c-`.
 */

/**
 * 1. Allow us to style box model properties.
 * 2. Line different sized buttons up a little nicer.
 * 3. Make buttons inherit font styles (often necessary when styling `input`s as
 *    buttons).
 * 4. Reset/normalize some styles.
 * 5. Force all button-styled elements to appear clickable.
 */

.c-btn {
    position: relative;
    display: inline-block; /* [1] */
    vertical-align: middle; /* [2] */
    font: inherit; /* [3] */
    text-align: center; /* [4] */
    margin: 0; /* [4] */
    cursor: pointer; /* [5] */
    padding: 0;
    border: 0;
    background: transparent;
    transition: $global-transition;
    color: $color-white;
    text-decoration: none;
    appearance: none !important;

    &__label {
        @extend %nowrap;
        padding-left: $unit-tiny;
    }

    + .c-btn {
        margin-left: $unit-small;
    }

    &:focus {
        outline: none;
    }

    &--text {
        padding: $unit-small $unit;
        background: $color-tertiary;
        text-transform: uppercase;
        letter-spacing: 1px;
        border-radius: $global-radius;
        @include font-size(16px);
        line-height: 1.2em;
        width: 100%;
        max-width: 70%;
        box-shadow: $global-drop-shadow;
        -webkit-tap-highlight-color: $color-transparent;
        -moz-tap-highlight-color: $color-transparent;
        @extend %nowrap;

        &:before {
            position: absolute;
            content: '';
            left: 0;
            top: 0;
            width: 100%;
            height: 100%;
            border-radius: $global-radius;
            background: $color-white;
            opacity: 0;
            transition: $global-transition;
        }

        &:hover {
            &:before {
                opacity: 0.1;
            }
        }

        &:active {
            transform: scale(0.98);
        }
    }

    &--gift {
        padding: $unit-small $unit;
        background: white;
        text-transform: uppercase;
        letter-spacing: 1px;
        border-radius: $global-radius;
        @include font-size(16px);
        line-height: 1.2em;
        width: 100%;
        max-width: 100%;
        box-shadow: $global-full-shadow;
        -webkit-tap-highlight-color: $color-transparent;
        -moz-tap-highlight-color: $color-transparent;
        @extend %nowrap;

        &:before {
            position: absolute;
            content: '';
            left: 0;
            top: 0;
            width: 100%;
            height: 100%;
            border-radius: $global-radius;
            background: $color-white;
            opacity: 0;
            transition: $global-transition;
        }

        &:hover {
            &:before {
                opacity: 0.1;
            }
        }

        &:active {
            transform: scale(0.98);
        }
    }
}

.c-btn:any-link {
    text-decoration: none;
    color: inherit;
}

.c-btn--small {
    width: 30px;
    height: 30px;
}
